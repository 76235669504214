import React from 'react';
import SurveyFormat from '../features/Survey/SurveyFormat';

function Survey() {
  return (
    <div>
      <SurveyFormat />
    </div>
  );
}

export default Survey;
